import axios from "axios";

export default class APITokens {

  static getLocalTokens() {
    return {
      access_token: localStorage.getItem("access_token"),
      refresh_token: localStorage.getItem("refresh_token"),
    };
  };

  static async refreshToken(baseURL: string) {
    try {
      const tokens = this.getLocalTokens();
      const response = await axios.post(`${baseURL}/auth/refresh-token`, tokens);
      const { access_token } = response.data;
      
      return access_token;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    };
  };
}